<template>
  <div id="global-login">
    <div id="bk-login"></div>
    <div class="container" style="position:relative">
      <header>
        <div>
          <img class="logo" src="@/assets/BizScreen_Horizontal_White.svg" />
          <!--img class="logo" src="https://biz-screen.com/wp-content/uploads/2022/01/Logo_BizscreenFinal_Blanc.svg" /-->
        </div>
        <div>
          <select v-model="lang" class="form-select" @change="langChange">
            <option value="fr">Francais</option>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="de">Deutsch</option>
            <option value="it">Italien</option>
          </select>
        </div>
      </header>
      <div class="c-app flex-row align-items-center">
        <CContainer>
          <CRow class="justify-content-center">
            <CCol md="5">
              <CCardGroup class="group-card">
                <CCard class="p-4 card-login">
                  <CCardBody>
                    <form @submit.prevent="handleLogin">
                      <h1 class="text-center">{{ $t("login") }}</h1>
                      <p
                        class="text-muted text-center"
                        style="margin-bottom: 3rem"
                      >
                        {{ $t("sign_in_your") }}
                      </p>
                      <CInput placeholder="Email" v-model="form.email">
                        <template #prepend-content
                          ><CIcon name="cil-user"
                        /></template>
                      </CInput>
                      <CInput
                        :placeholder="$t('pwd')"
                        type="password"
                        autocomplete="curent-password"
                        v-model="form.password"
                      >
                        <template #prepend-content
                          ><CIcon name="cil-lock-locked"
                        /></template>
                      </CInput>

                      <CRow style="margin-top: 50px">
                        <CCol class="text-left">
                          <button type="submit" class="btn px-6 btn-primary">
                            {{ $t("login") }}
                          </button>
                        </CCol>

                        <!--CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CButton color="link" class="d-lg-none">Register now!</CButton>
                    </CCol-->
                      </CRow>
                      <CRow
                        ><CCol>
                          <div style="margin-top: 10px">
                            <CAlert :show.sync="errors" color="danger">{{
                              $t("error_login")
                            }}</CAlert>
                          </div>
                        </CCol>
                      </CRow>
                      <CRow class="line_link">
                        <CCol><a :href="url+'/lng/register/' + lang" target="_blank">{{ $t("no_account") }}</a></CCol>
                        <CCol class="text-right"><a :href="url+'/password/reset'" target="_blank">{{ $t("no_pwd") }}</a></CCol>
                      </CRow>
                    </form>
                  </CCardBody>
                </CCard>
                <!--CCard
              color="darkgrey"
              text-color="white"
              class="text-center py-5 d-md-down-none col-logo"
              body-wrapper
            >
              <img src="@/assets/logo_bizzscreen.png" />
            </CCard-->
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
      <footer>{{ $t("rights") }} | {{ $t("create_by") }}</footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: { email: null, password: null },
      errors: 0,
      error_msg: "",
      lang: this.$i18n.locale,
      url: process.env.VUE_APP_API_URL
    };
  },
  methods: {
    async handleLogin() {
      try {
        await window.axios.get("/sanctum/csrf-cookie");
        let rep = await window.axios.post("/login", this.form);
        console.log(rep);

        let response = await window.axios.get("/api/user");
        this.$store.commit("setAuth", response.data);

        console.log(response);

        this.$router.push("/dashboard");
        console.log("end");
      } catch (error) {
        this.errors = 1;
        this.error_msg = error.response.data.message;
        console.log(error);
      }
    },
    checkEmail() {
      const mailformat = /\S+@\S+\.\S+/;

      return mailformat.test(this.form.email);
    },
    langChange(){
      this.$i18n.locale = this.lang
      localStorage.setItem('lang', this.lang)
    },
  },
};
</script>

<style scoped>
.col-logo,
.bg-darkgrey {
  background-color: #222222 !important;
}
.col-logo img {
  max-width: 180px;
}

.alert-danger {
  padding: 0.4rem;
}

#bk-login {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-image: url(https://biz-screen.com/wp-content/uploads/2020/09/tech-startup-hero-bg-color-shape-orange.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  z-index:99;
}

.c-app,
#global-login {
  background-color: #257cff !important;
}

header .logo {
  height: 50px;
}

footer{
  position: absolute;
  bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 99;
    color: #ffffffad;
    padding: 10px;
    font-size: 13px;
}

header {
  padding: 15px 0;
  position: absolute;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button {
  width: 100%;
  padding-bottom: 8px;
  padding-top: 8px;
}

.card-login {
  background-color: #fafafa !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.16) 0px 3px 6px;
  border-radius: 0.5rem !important;
  z-index: 9999;
}

select{
  background-color: transparent;
  color:white;
  border:none;
  font-size: 1rem;
    padding: 5px 5px;
}
select:hover{
  cursor: pointer;
}

option{
  color:#3c4b64;
}

.line_link{
  font-size: 12px;
    /* position: relative; */
    /* top: 13px; */
    margin-top: 20px;
    
}
.line_link a, .line_link:hover{
  color: #3c4b64;
}
</style>
